<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    :append-to-body="true">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px">
      <el-form-item label="字段名称" prop="columnName">
        <el-input v-model="dataForm.columnName" placeholder="字段名称" maxlength="100" show-word-limit></el-input>
      </el-form-item>
      <el-form-item label="字段类型" prop="columnType">
        <sysDictSelect v-model="dataForm.columnType" dictType="column_type" placeholder="请选择字段类型" filterable/>
      </el-form-item>
      <el-form-item label="字段长度" prop="columnLength">
        <el-input-number
          v-model="dataForm.columnLength"
          controls-position="right"
          :min="1" :max="999999999"
          :step="1" step-strictly
          placeholder="字段长度">
        </el-input-number>
      </el-form-item>
      <el-form-item label="中文备注" prop="columnRemark">
        <el-input v-model="dataForm.columnRemark" placeholder="中文备注" maxlength="200" show-word-limit></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" :loading="isOnSubmit">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" :loading="isOnSubmit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      isOnSubmit: false,
      dataForm: {
        id: null,
        dataModelId: '',
        columnName: '',
        columnType: '',
        columnLength: '',
        columnRemark: '',
        customColumnType: '',
        virtualColumn: '',
        virtualType: '',
        virtualContent: ''
      },
      dataRule: {
        columnName: [{ required: true, message: '字段名称不能为空', trigger: 'blur' }],
        columnType: [{ required: true, message: '请选择字段类型', trigger: ['blur','change'] }],
        columnLength: [{ required: true, message: '字段长度不能为空', trigger: 'blur' }],
        columnRemark: [{ required: true, message: '中文备注不能为空', trigger: 'blur' }],
      }
    }
  },
  methods: {
    init (data) {
      this.visible = true
      this.isOnSubmit = false
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields()
        if (data) {
          for (let item in this.dataForm) {
            if (data[item] || data[item] === 0) {
              this.dataForm[item] = data[item] + ''
            } else {
              this.dataForm[item] = ''
            }
          }
        }else{
          this.dataForm = {
            id: null,
            dataModelId: '',
            columnName: '',
            columnType: '',
            columnLength: '',
            columnRemark: '',
            customColumnType: '',
            virtualColumn: '',
            virtualType: '',
            virtualContent: ''
          }
        }
      })
    },
    // 表单提交
    dataFormSubmit () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.isOnSubmit = true
          const data_ = JSON.parse(JSON.stringify(this.dataForm))
          this.$emit('backFormPage', data_)
        }
      })
    }
  }
}
</script>
